import React from "react";
import { /*Header,*/ MainPage, Footer } from "./components";
import "./main.css";

export default function App() {
  return (
    <>
      {/* <Header /> */}
      <MainPage />
      <Footer />
    </>
  );
}
